<template>
  <div class="mx-2 mb-2 mt-2 paginate-area">
    <div class="row">
      <div
        class="col-md-6 col-12 d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span>
          Exibindo {{ rowsTable }} de {{ perPage }} com total de
          {{ totalRows }} resultados
        </span>
      </div>
      <div
        class="col-md-6 col-12 d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :limit="10"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="my-1"
          @input="updatePage()"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination } from "bootstrap-vue";

export default {
  props: {
    rowsTable: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 50,
    },
    totalRows: {
      type: Number,
      default: 0,
    },
    initialPage: {
      type: Number,
      default: 1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  components: {
    BPagination,
  },
  methods: {
    updatePage() {
      this.$root.$emit("paginate:update", {
        _rowsTable: this.rowsTable,
        _perPage: this.perPage,
        _totalRows: this.totalRows,
        _currentPage: this.currentPage,
      });
    },
  },
};
</script>
