var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-2 mb-2 mt-2 paginate-area" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass:
            "col-md-6 col-12 d-flex align-items-center justify-content-center justify-content-sm-start",
        },
        [
          _c("span", [
            _vm._v(
              " Exibindo " +
                _vm._s(_vm.rowsTable) +
                " de " +
                _vm._s(_vm.perPage) +
                " com total de " +
                _vm._s(_vm.totalRows) +
                " resultados "
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "col-md-6 col-12 d-flex align-items-center justify-content-center justify-content-sm-end",
        },
        [
          _c("b-pagination", {
            staticClass: "my-1",
            attrs: {
              "total-rows": _vm.totalRows,
              "per-page": _vm.perPage,
              limit: 10,
              "first-number": "",
              "last-number": "",
              "prev-class": "prev-item",
              "next-class": "next-item",
            },
            on: {
              input: function ($event) {
                return _vm.updatePage()
              },
            },
            scopedSlots: _vm._u([
              {
                key: "prev-text",
                fn: function () {
                  return [
                    _c("feather-icon", {
                      attrs: { icon: "ChevronLeftIcon", size: "18" },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "next-text",
                fn: function () {
                  return [
                    _c("feather-icon", {
                      attrs: { icon: "ChevronRightIcon", size: "18" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }