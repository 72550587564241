var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-1" },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getData()
                },
              },
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-md-2" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.id,
                          expression: "filters.id",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "tel", placeholder: "ID" },
                      domProps: { value: _vm.filters.id },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "id", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.document_number,
                          expression: "filters.document_number",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "tel",
                        placeholder: "Número do documento",
                      },
                      domProps: { value: _vm.filters.document_number },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.filters,
                            "document_number",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            searchable: false,
                            clearable: true,
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsDocumentTypes,
                            placeholder: "Tipo de documento",
                          },
                          model: {
                            value: _vm.filters.document_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "document_type", $$v)
                            },
                            expression: "filters.document_type",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.company,
                          expression: "filters.company",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "tel", placeholder: "Nome do fornecedor" },
                      domProps: { value: _vm.filters.company },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "company", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            searchable: false,
                            clearable: true,
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsStatus,
                            placeholder: "Status",
                          },
                          model: {
                            value: _vm.filters.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "status", $$v)
                            },
                            expression: "filters.status",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            searchable: false,
                            clearable: true,
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsSteps,
                            placeholder: "Aprovação",
                          },
                          model: {
                            value: _vm.filters.step,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "step", $$v)
                            },
                            expression: "filters.step",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c("flat-pickr", {
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Período de criação",
                          config: {
                            mode: "range",
                            altInput: true,
                            dateFormat: "Y-m-d",
                            altFormat: "d/m/Y",
                            locale: "pt",
                          },
                        },
                        model: {
                          value: _vm.filters.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "period", $$v)
                          },
                          expression: "filters.period",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-1 offset-md-1" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-block",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm.submitedFilter
                        ? _c(
                            "div",
                            [
                              _c("b-spinner", {
                                attrs: { label: "Loading...", small: "" },
                              }),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("i", {
                              staticClass: "bi bi-search cursor-pointer cursor",
                            }),
                          ]),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.$can("Lançado - Excluir", "Financeiro - ADM") ||
      _vm.$can("Lançado - Cancelar", "Financeiro - ADM")
        ? _c("div", { staticClass: "form-row justify-content-end mb-2" }, [
            _vm.$can("Lançado - Excluir", "Financeiro - ADM")
              ? _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-warning btn-block",
                      attrs: {
                        disabled: _vm.selectedItems.length < 1 ? true : false,
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          _vm.changeStatus(2, 5), "Excluir"
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass:
                          "bi bi-hand-thumbs-down cursor-pointer cursor mr-1",
                        staticStyle: {
                          "font-size": "1.2rem",
                          "line-height": "1.5",
                        },
                      }),
                      _vm._v(" Excluir "),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.$can("Lançado - Cancelar", "Financeiro - ADM")
              ? _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-success btn-block",
                      attrs: {
                        disabled: _vm.selectedItems.length < 1 ? true : false,
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeStatus(3, 2, "Cancelar")
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass:
                          "bi bi-hand-thumbs-up cursor-pointer cursor mr-1",
                        staticStyle: {
                          "font-size": "1.2rem",
                          "line-height": "1.5",
                        },
                      }),
                      _vm._v(" Cancelar "),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.$can("Lançado - Aprovar", "Financeiro - ADM")
              ? _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-success btn-block",
                      attrs: {
                        disabled: _vm.selectedItems.length < 1 ? true : false,
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeStatus(
                            _vm.userData.role === "Financeiro - CFO" ? 3 : 2,
                            2,
                            "Aprovar"
                          )
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass:
                          "bi bi-hand-thumbs-up cursor-pointer cursor mr-1",
                        staticStyle: {
                          "font-size": "1.2rem",
                          "line-height": "1.5",
                        },
                      }),
                      _vm._v(" Aprovar "),
                    ]
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u(
              [
                _vm.$can("Lançado - Aprovar", "Financeiro - ADM") ||
                _vm.$can("Lançado - Excluir", "Financeiro - ADM")
                  ? {
                      key: "head(select)",
                      fn: function () {
                        return [
                          _c("b-form-checkbox", {
                            on: { change: _vm.toggleSelectAll },
                            model: {
                              value: _vm.selectAllCheckbox,
                              callback: function ($$v) {
                                _vm.selectAllCheckbox = $$v
                              },
                              expression: "selectAllCheckbox",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                _vm.$can("Lançado - Aprovar", "Financeiro - ADM") ||
                _vm.$can("Lançado - Excluir", "Financeiro - ADM")
                  ? {
                      key: "cell(select)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          item.status.title !== "Reprovado" &&
                          item.status.title !== "Cancelado" &&
                          item.step.current.title === "Lançado"
                            ? _c("b-form-checkbox", {
                                staticStyle: {
                                  "margin-left": "13px",
                                  padding: "0px",
                                  width: "20px",
                                  margin: "auto",
                                },
                                attrs: { value: item.uuid },
                                model: {
                                  value: _vm.selectedItems,
                                  callback: function ($$v) {
                                    _vm.selectedItems = $$v
                                  },
                                  expression: "selectedItems",
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    }
                  : null,
                {
                  key: "cell(supplier)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.supplier
                        ? _c("div", { staticClass: "strategic-units" }, [
                            _c("strong", [
                              _vm._v(_vm._s(item.supplier.company_name)),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(plan)",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.plan.title) + " ")]
                  },
                },
                {
                  key: "cell(document_type)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "grid",
                            "justify-content": "center",
                            "align-items": "center",
                            "justify-items": "center",
                          },
                        },
                        [
                          _c("div", [
                            _c("span", [_vm._v(_vm._s(item.document_number))]),
                          ]),
                          _c("div", [
                            item.files
                              ? _c("strong", [
                                  _c("i", {
                                    staticClass: "bi bi-paperclip text-primary",
                                  }),
                                ])
                              : _vm._e(),
                            item.document_type
                              ? _c("span", [
                                  _vm._v(
                                    " " + _vm._s(item.document_type.title) + " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "cell(user)",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.user.title) + " ")]
                  },
                },
                {
                  key: "cell(status)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "strong",
                        {
                          staticClass: "d-block",
                          class: _vm.getStatus(item.status.title),
                        },
                        [_vm._v(" " + _vm._s(item.status.title) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "cell(step)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      !item.step.next
                        ? _c(
                            "span",
                            {
                              staticClass: "d-block",
                              style:
                                "color: rgba(40, 199, 111, 0.99); background-color: rgba(40, 199, 111, 0.15); font-size: 0.85rem; padding: 5px 5px; border-radius: 3px",
                            },
                            [
                              _c("span", { staticClass: "d-block" }, [
                                _vm._v("APROVADO"),
                              ]),
                              _c("span", { staticClass: "d-block" }, [
                                _vm._v(_vm._s(item.step.current.title)),
                              ]),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center",
                            },
                            [
                              _c("div", { staticClass: "text-primary" }, [
                                _c("strong", { staticClass: "d-block" }, [
                                  _vm._v(_vm._s(item.step.current.title)),
                                ]),
                                item.step.current.user_name
                                  ? _c("span", { staticClass: "d-block" }, [
                                      _vm._v(
                                        _vm._s(item.step.current.user_name)
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("feather-icon", {
                                    staticClass: "text-dark",
                                    staticStyle: {
                                      margin: "0px 7px !important",
                                    },
                                    attrs: {
                                      icon: "ChevronsRightIcon",
                                      size: "16",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "text-success" }, [
                                _c("strong", { staticClass: "d-block" }, [
                                  _vm._v(_vm._s(item.step.next.title)),
                                ]),
                                item.step.next.user_name
                                  ? _c("span", { staticClass: "d-block" }, [
                                      _vm._v(_vm._s(item.step.next.user_name)),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                    ]
                  },
                },
                {
                  key: "cell(total)",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(_vm._s(_vm._f("toCurrency")(item.total)))]
                  },
                },
                {
                  key: "cell(actions)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm.$can("Lançado - Editar", "Financeiro - ADM") ||
                      _vm.$can("Lançado - Aprovar", "Financeiro - ADM") ||
                      _vm.$can("Lançado - Deletar", "Financeiro - ADM") ||
                      _vm.$can("Lançado - Visualizar", "Financeiro - ADM")
                        ? _c(
                            "b-dropdown",
                            {
                              staticClass: "dropdown-actions",
                              attrs: {
                                size: "sm",
                                right: "",
                                variant: "link",
                                "no-caret": "",
                                "toggle-class": "p-0",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "MoreVerticalIcon",
                                            size: "15",
                                            variant: "dark",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm.$can(
                                "Lançado - Editar",
                                "Financeiro - ADM"
                              ) && item.step.current.title !== "Financeiro"
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "orders-purchases-edit",
                                            params: { uuid: item.uuid },
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "cursor-pointer",
                                        attrs: { icon: "EditIcon", size: "15" },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "align-middle ml-50" },
                                        [_vm._v("Editar")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$can(
                                "Lançado - Aprovar",
                                "Financeiro - ADM"
                              ) && item.purchase_order_step_id === 1
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmApproval(item.uuid)
                                        },
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass:
                                          "cursor-pointer text-success",
                                        attrs: {
                                          icon: "ThumbsUpIcon",
                                          size: "15",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "align-middle ml-50 text-success",
                                        },
                                        [_vm._v("Aprovar")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$can(
                                "Lançado - Visualizar",
                                "Financeiro - ADM"
                              )
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "orders-purchases-print",
                                            params: { uuid: item.uuid },
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "cursor-pointer",
                                        attrs: {
                                          icon: "FileTextIcon",
                                          size: "15",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "align-middle ml-50" },
                                        [_vm._v("Visualizar")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.step.current.title === "Financeiro - ADM"
                                ? _c("b-dropdown-divider")
                                : _vm._e(),
                              _vm.$can(
                                "Lançado - Deletar",
                                "Financeiro - ADM"
                              ) && item.step.current.title === "Lançado"
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmDelete(item.uuid)
                                        },
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass:
                                          "cursor-pointer text-danger",
                                        attrs: {
                                          icon: "TrashIcon",
                                          size: "15",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "align-middle ml-50 text-danger",
                                        },
                                        [_vm._v("Deletar")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.filters.currentPage,
              "per-page": _vm.filters.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }