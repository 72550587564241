const getStatusPurchaseOrderStatus = (name) => {
  const statusClasses = {
    'Reprovado': 'text-warning',
    'Cancelado': 'text-danger',
    'Lançado': 'text-primary'
  };

  return statusClasses[name] || 'text-success';
}

module.exports = {
  getStatusPurchaseOrderStatus,
}