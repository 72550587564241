var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        [
          _c("b-card-title", [_vm._v("Despesar por categoria")]),
          _c(
            "b-dropdown",
            {
              staticClass: "chart-dropdown ",
              attrs: {
                text: "Últimos 7 dias",
                variant: "transparent",
                right: "",
                "no-caret": "",
                "toggle-class": "p-0 mb-25",
                size: "sm",
              },
            },
            _vm._l(_vm.chartData.lastDays, function (day) {
              return _c("b-dropdown-item", { key: day }, [
                _vm._v(" " + _vm._s(day) + " "),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-body",
        [
          _c("vue-apex-charts", {
            staticClass: "my-2",
            attrs: {
              type: "donut",
              height: "300",
              options: _vm.sessionsByDeviceDonut.chartOptions,
              series: _vm.sessionsByDeviceDonut.series,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }