<template>
  <div>
    <b-card class="mb-1">
      <b-form @submit.prevent="getData()">
        <div class="form-row">
          <div class="col-md-2">
            <div class="form-group">
              <input
                type="tel"
                v-model="filters.id"
                placeholder="ID"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <input
                type="tel"
                v-model="filters.document_number"
                placeholder="Número do documento"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <v-select
                label="title"
                :searchable="false"
                :clearable="true"
                item-text="title"
                item-value="code"
                :options="optionsDocumentTypes"
                v-model="filters.document_type"
                placeholder="Tipo de documento"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <input
                type="tel"
                v-model="filters.company"
                placeholder="Nome do fornecedor"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <v-select
                label="title"
                :searchable="false"
                :clearable="true"
                item-text="title"
                item-value="code"
                :options="optionsStatus"
                v-model="filters.status"
                placeholder="Status"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <v-select
                label="title"
                :searchable="false"
                :clearable="true"
                item-text="title"
                item-value="code"
                :options="optionsSteps"
                v-model="filters.step"
                placeholder="Aprovação"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-md-0">
              <flat-pickr
                v-model="filters.period"
                class="form-control"
                placeholder="Período de criação"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>
          <div class="col-md-1 offset-md-1">
            <button type="submit" class="btn btn-primary btn-block">
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <i class="bi bi-search cursor-pointer cursor"></i>
              </div>
            </button>
          </div>
        </div>
      </b-form>
    </b-card>

    <!-- <div class="form-row match-height">
      <div class="col-md-5">
        <card-analytic-product-orders />
      </div>
      <div class="col-md-7">
        <div class="form-row">
          <div class="xl-2 col-md-6 sm-6">
            <statistic-card-vertical
              icon="bi-exclamation-diamond"
              statistic="36.9k"
              statistic-title="Vencidos (R$)"
              color="danger"
            />
          </div>
          <div class="xl-2 col-md-6 sm-6">
            <statistic-card-vertical
              color="warning"
              icon="bi-hourglass"
              statistic="12k"
              statistic-title="Vencem hoje (R$)"
            />
          </div>
          <div class="xl-2 col-md-6 sm-6">
            <statistic-card-vertical
              color="info"
              icon="bi-calendar3"
              statistic="97.8k"
              statistic-title="A vencer (R$)"
            />
          </div>
          <div class="xl-2 col-md-6 sm-6">
            <statistic-card-vertical
              color="success"
              icon="bi-check-circle"
              statistic="26.8"
              statistic-title="Pagos (R$)"
            />
          </div>
          <div class="xl-2 col-md-12 sm-6">
            <statistic-card-vertical
              color="primary"
              icon="bi-cash-coin"
              statistic="689"
              statistic-title="Total do período (R$)"
            />
          </div>
        </div>
      </div>
    </div> -->
    <div
      v-if="
        $can('Lançado - Excluir', 'Financeiro - ADM') ||
        $can('Lançado - Cancelar', 'Financeiro - ADM')
      "
      class="form-row justify-content-end mb-2"
    >
      <div
        class="col-md-2"
        v-if="$can('Lançado - Excluir', 'Financeiro - ADM')"
      >
        <button
          :disabled="selectedItems.length < 1 ? true : false"
          type="button"
          class="btn btn-sm btn-warning btn-block"
          @click="changeStatus(2, 5), 'Excluir'"
        >
          <i
            style="font-size: 1.2rem; line-height: 1.5"
            class="bi bi-hand-thumbs-down cursor-pointer cursor mr-1"
          ></i>
          Excluir
        </button>
      </div>
      <div
        v-if="$can('Lançado - Cancelar', 'Financeiro - ADM')"
        class="col-md-2"
      >
        <button
          :disabled="selectedItems.length < 1 ? true : false"
          type="button"
          class="btn btn-sm btn-success btn-block"
          @click="changeStatus(3, 2, 'Cancelar')"
        >
          <i
            style="font-size: 1.2rem; line-height: 1.5"
            class="bi bi-hand-thumbs-up cursor-pointer cursor mr-1"
          ></i>
          Cancelar
        </button>
      </div>
      <div
        class="col-md-2"
        v-if="$can('Lançado - Aprovar', 'Financeiro - ADM')"
      >
        <button
          :disabled="selectedItems.length < 1 ? true : false"
          type="button"
          class="btn btn-sm btn-success btn-block"
          @click="
            changeStatus(
              userData.role === 'Financeiro - CFO' ? 3 : 2,
              2,
              'Aprovar'
            )
          "
        >
          <i
            style="font-size: 1.2rem; line-height: 1.5"
            class="bi bi-hand-thumbs-up cursor-pointer cursor mr-1"
          ></i>
          Aprovar
        </button>
      </div>
    </div>

    <b-card no-body>
      <b-table
        sticky-header
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        class="position-relative"
      >
        <template
          #head(select)=""
          v-if="
            $can('Lançado - Aprovar', 'Financeiro - ADM') ||
            $can('Lançado - Excluir', 'Financeiro - ADM')
          "
        >
          <b-form-checkbox
            v-model="selectAllCheckbox"
            @change="toggleSelectAll"
          />
        </template>

        <template
          #cell(select)="{ item }"
          v-if="
            $can('Lançado - Aprovar', 'Financeiro - ADM') ||
            $can('Lançado - Excluir', 'Financeiro - ADM')
          "
        >
          <b-form-checkbox
            v-if="
              item.status.title !== 'Reprovado' &&
              item.status.title !== 'Cancelado' &&
              item.step.current.title === 'Lançado'
            "
            style="margin-left: 13px; padding: 0px; width: 20px; margin: auto"
            :value="item.uuid"
            v-model="selectedItems"
          />
        </template>

        <template #cell(supplier)="{ item }">
          <div class="strategic-units" v-if="item.supplier">
            <strong>{{ item.supplier.company_name }}</strong>
          </div>
        </template>

        <template #cell(plan)="{ item }">
          {{ item.plan.title }}
        </template>

        <template #cell(document_type)="{ item }">
          <div
            style="
              display: grid;
              justify-content: center;
              align-items: center;
              justify-items: center;
            "
          >
            <div>
              <span>{{ item.document_number }}</span>
            </div>
            <div>
              <strong v-if="item.files">
                <i class="bi bi-paperclip text-primary"></i>
              </strong>
              <span v-if="item.document_type">
                {{ item.document_type.title }}
              </span>
            </div>
          </div>
        </template>

        <template #cell(user)="{ item }">
          {{ item.user.title }}
        </template>

        <template #cell(status)="{ item }">
          <strong class="d-block" :class="getStatus(item.status.title)">
            {{ item.status.title }}
          </strong>
        </template>

        <template #cell(step)="{ item }">
          <span
            v-if="!item.step.next"
            class="d-block"
            :style="`color: rgba(40, 199, 111, 0.99); background-color: rgba(40, 199, 111, 0.15); font-size: 0.85rem; padding: 5px 5px; border-radius: 3px`"
          >
            <span class="d-block">APROVADO</span>
            <span class="d-block">{{ item.step.current.title }}</span>
          </span>

          <div v-else class="d-flex justify-content-center align-items-center">
            <div class="text-primary">
              <strong class="d-block">{{ item.step.current.title }}</strong>
              <span class="d-block" v-if="item.step.current.user_name">{{
                item.step.current.user_name
              }}</span>
            </div>
            <div>
              <feather-icon
                icon="ChevronsRightIcon"
                class="text-dark"
                size="16"
                style="margin: 0px 7px !important"
              />
            </div>
            <div class="text-success">
              <strong class="d-block">{{ item.step.next.title }}</strong>
              <span class="d-block" v-if="item.step.next.user_name">{{
                item.step.next.user_name
              }}</span>
            </div>
          </div>
        </template>

        <template #cell(total)="{ item }">{{
          item.total | toCurrency
        }}</template>

        <template #cell(actions)="{ item }">
          <b-dropdown
            v-if="
              $can('Lançado - Editar', 'Financeiro - ADM') ||
              $can('Lançado - Aprovar', 'Financeiro - ADM') ||
              $can('Lançado - Deletar', 'Financeiro - ADM') ||
              $can('Lançado - Visualizar', 'Financeiro - ADM')
            "
            size="sm"
            right
            variant="link"
            no-caret
            toggle-class="p-0"
            class="dropdown-actions"
          >
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="15" variant="dark" />
            </template>

            <b-dropdown-item
              v-if="
                $can('Lançado - Editar', 'Financeiro - ADM') &&
                item.step.current.title !== 'Financeiro'
              "
              @click="
                $router.push({
                  name: 'orders-purchases-edit',
                  params: { uuid: item.uuid },
                })
              "
            >
              <feather-icon icon="EditIcon" class="cursor-pointer" size="15" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                $can('Lançado - Aprovar', 'Financeiro - ADM') &&
                item.purchase_order_step_id === 1
              "
              @click="confirmApproval(item.uuid)"
            >
              <feather-icon
                icon="ThumbsUpIcon"
                size="15"
                class="cursor-pointer text-success"
              />
              <span class="align-middle ml-50 text-success">Aprovar</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('Lançado - Visualizar', 'Financeiro - ADM')"
              @click="
                $router.push({
                  name: 'orders-purchases-print',
                  params: { uuid: item.uuid },
                })
              "
            >
              <feather-icon
                icon="FileTextIcon"
                size="15"
                class="cursor-pointer"
              />
              <span class="align-middle ml-50">Visualizar</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="$router.push({ name: 'orders-purchases-create', params: { uuid: item.uuid } })">
              <feather-icon icon="CopyIcon" size="15" class="cursor-pointer" />
              <span class="align-middle ml-50">Duplicar</span>
            </b-dropdown-item> -->

            <b-dropdown-divider
              v-if="item.step.current.title === 'Financeiro - ADM'"
            />

            <b-dropdown-item
              v-if="
                $can('Lançado - Deletar', 'Financeiro - ADM') &&
                item.step.current.title === 'Lançado'
              "
              @click="confirmDelete(item.uuid)"
            >
              <feather-icon
                icon="TrashIcon"
                size="15"
                class="cursor-pointer text-danger"
              />
              <span class="align-middle ml-50 text-danger">Deletar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  VBTooltip,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import ChartjsComponentLineChart from "@/components/chart/ChartjsComponentLineChart";
import chartjsData from "./chartjsData";

import StatisticCardVertical from "./CardAnalistPeriod";

import CardAnalyticProductOrders from "./CardAnalyticProductOrders";

import CustomPaginateTable from "@/views/components/custom/PaginationTable";

import vSelect from "vue-select";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import { getStatusPurchaseOrderStatus } from "@/_helpers/_item";

export default {
  components: {
    BCard,
    flatPickr,
    vSelect,
    BTable,
    CardAnalyticProductOrders,
    ChartjsComponentLineChart,
    StatisticCardVertical,
    BDropdownDivider,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BDropdown,
    BDropdownItem,
    CustomPaginateTable,
    BForm,
    BFormCheckbox,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      submitedFilter: false,
      filters: {
        id: "",
        company: "",
        document_type: "",
        document_number: "",
        status: "",
        period: "",
        currentPage: 1,
        perPage: 100,
        step: "",
      },
      optionsStatus: [],
      optionsSteps: [],
      optionsDocumentTypes: [],
      chartjsData,
      totalRows: 0,
      rowsTable: 0,
      tableColumns: [
        {
          key: "select",
          label: "",
          class: "text-center",
          thStyle: { width: "20px" },
        },
        {
          key: "id",
          label: "ID",
          class: "text-center",
          thStyle: { width: "50px" },
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: { width: "30px" },
        },
        {
          key: "document_type",
          label: "Tipo Doc.",
          class: "text-center",
          thStyle: { width: "150px" },
        },
        {
          key: "supplier",
          label: "Fornecedor",
          class: "text-left",
          thStyle: { width: "290px" },
        },
        {
          key: "plan",
          label: "Histórico PC",
          class: "text-center",
          thStyle: { width: "250px" },
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          thStyle: { width: "80px" },
        },
        {
          key: "total",
          label: "Total",
          class: "text-center",
          thStyle: { width: "130px" },
        },
        {
          key: "step",
          label: "Aprovação",
          class: "text-center",
          thStyle: { width: "230px" },
        },
        {
          key: "user",
          label: "Usuário",
          class: "text-center",
          thStyle: { width: "100px" },
        },
        {
          key: "created_at",
          label: "Cadastro",
          class: "text-center",
          thStyle: { width: "130px" },
        },
      ],
      dataItems: [],
      itemsSelecteds: [],
      selectedItems: [],
      selectAllCheckbox: false,
    };
  },
  watch: {
    selectedItems(newVal) {
      const items = this.dataItems.filter(
        (item) => item.step.current.title === "Lançado"
      ).length;
      if (newVal.length === 0 && items === 0) {
        this.selectAllCheckbox = false;
      } else {
        this.selectAllCheckbox = newVal.length === items;
      }
    },
  },
  methods: {
    getStatus(status) {
      return getStatusPurchaseOrderStatus(status);
    },
    changeStatus(step, status, statusName) {
      this.$swal({
        title: `${statusName} pedido de compra`,
        text: `Você tem certeza que deseja alterar o status do pedido de compra para ${statusName}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, tenho!",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("OrderPurchase/updatePurchaseOrderStep", {
              items: this.selectedItems,
              step: step,
              status: status,
            })
            .then((response) => {
              this.getData();
              this.$swal({
                icon: "success",
                title: "Pedido aprovado!",
                text: "Operação realizada com sucesso.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              if (response.moviment && response.moviment.id) {
                window.open(
                  this.$router.resolve({
                    name: "orders-purchases-romaneio-view",
                    params: { uuid: response.moviment.id },
                  }).href,
                  "_blank"
                );
              }
            });
        }
      });
    },
    toggleSelectAll() {
      this.selectedItems = this.selectAllCheckbox
        ? this.dataItems
            .filter((item) => item.step.current.title === "Lançado")
            .map((item) => item.uuid)
        : [];
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("OrderPurchase/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
    confirmApproval(uuid) {
      this.$swal({
        title: "Aprovar pedido de compra",
        text: "Você tem certeza que deseja aprovar o pedido de compra?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, quero aprovar!",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("OrderPurchase/updatePurchaseOrderStep", {
              items: [uuid],
              step: 2,
            })
            .then(() => {
              this.getData();
              this.$swal({
                icon: "success",
                title: "Pedido aprovado!",
                text: "Operação realizada com sucesso.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
    async getData() {
      const filtersData = {
        id: this.filters.id,
        document_number: this.filters.document_number,
        document_type: this.filters.document_type
          ? this.filters.document_type.code
          : "",
        status: this.filters.status ? this.filters.status.code : "",
        perPage: this.filters.perPage,
        currentPage: this.filters.currentPage,
        step: this.filters.step ? this.filters.step.code : "",
        company: this.filters.company,
        period: this.filters.period,
      };

      this.$store.dispatch("OrderPurchase/all", filtersData).then((data) => {
        if (data) {
          this.dataItems = data.data;
          this.rowsTable = data.data.length;
          this.totalRows = data.meta.total;
          this.filters.currentPage = data.meta.current_page;

          for (let a = 0; a < this.dataItems.length; a++) {
            for (let n = 0; n < this.optionsSteps.length; n++) {
              if (this.dataItems[a].step.title === this.optionsSteps[n].title) {
                let next = "";

                if (this.optionsSteps[n + 1]) {
                  next = {
                    title: this.optionsSteps[n + 1].title,
                    user_name: this.optionsSteps[n + 1].user_name,
                  };
                }

                this.dataItems[a].step = {
                  current: this.dataItems[a].step,
                  next: next,
                };
              }
            }
          }
        }
      });
    },
  },
  async mounted() {
    this.getData();

    this.$store.dispatch("OrderPurchase/forSelectSteps").then((res) => {
      this.optionsSteps = res;
    });

    this.$store.dispatch("OrderPurchase/forSelectStatus").then((res) => {
      this.optionsStatus = res;
    });

    this.$store.dispatch("OrderPurchase/forSelectDocumentTypes").then((res) => {
      this.optionsDocumentTypes = res;
    });

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.getData();
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>

<style lang="scss" scoped>
.strategic-units {
  align-items: center;
  display: grid;
  align-content: center;
  justify-content: left;
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
